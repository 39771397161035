/*
 * @file cn.js
 * @author liushengxgi
 * @date 2020-09-03 10:52:17
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-03 11:00:49
 */

export default {
    menuData: {
        dashboardTitle: '控制台',
        editorialTitle: '編輯工具',
        domainTitle: '域名屏蔽',
        authorizeTitle: '權限管理',
        roleTitle: '角色管理',
        accountTitle: '賬戶管理',
        userTitle: '用戶管理',
        managementTitle: '媒體管理',
        audienceTitle: '受眾定向配置',
        sensitiveTagTitle: '敏感標籤列表',
        sensitiveCfgTitle: '配置敏感標籤',
        adxTitle: 'ssp管理',
        discoveryBWconfigTitle: 'D_SSP level 黑白名單',
        mediaBWconfigTitle: 'Account-level media blacklist/whitelist',
        mediaBWconfig: 'Account-level media blacklist/whitelist',
        mediaSellerIdConfigTitle: 'Account-level Seller ID blacklist/whitelist',
        cruiseRejectBlackListTitle: 'Cruise Rejection',
        creativeRiskTitle: 'Image Creative Risk Control',
        auditRejectOnceTitle: '单次拒审',
        xandrAuditManagment: 'Brand Name Review Management',
        xandrAuditManagmentTitle: 'Brand Name Review Management',
        accountSiteFilter: 'account级别-site流量过滤',
        accountSiteFilterTitle: 'account级别-site流量过滤'
    },
    button: {
        addAccount: '添加賬戶',
        addUser: '添加用戶',
        addRole: '添加角色',
        status: '狀態',
        del: '刪除',
        bwList: 'Black and white list'
    },
    dashboard: {
        bwType: 'Blacklist & Whitelist Type',
        mediaSearchAcc: '根據賬戶搜索用戶',
        rpm: 'RPM',
        vrpm: 'vRPM',
        totalRevenue: '總收入',
        matchedRequest: '匹配的廣告請求',
        returnAds: '返回廣告',
        viewsWithAds: '有廣告的觀看次數',
        clicks: '廣告點擊',
        withAds: '廣告點擊率',
        withVisibility: '廣告可視率',
        vctr: 'vCTR',
        rendersWithAds: '廣告渲染',
        winRate: 'Win Rate',
        avgCPC: '平均每次點擊費用',
        accountName: '用戶名',
        // Dimension
        dimensionDate: '日期',
        dimensionAccount: '帳戶',
        dimensionSSP: '媒體平台',
        dimensionSite: '站點',
        payin: '支付'
    },
    editorial: {
        domain: {
            searchPlaceholder: '搜索域名/賬戶名稱',
            remove: '從列表中移除',
            block: '添加屏蔽域名'
        }
    },
    permission: {},
    management: {
        rejectAutoAudit: 'Rejected for Review and Under Review of Automatically Resubmitted',
        noLimit: 'All',
        yes: 'Yes',
        no: 'No',
        illegalCharacter1: 'The use of Chinese semicolon/Chinese comma/English semicolon separation is not supported. Please use English comma, to separate ssp.',
        illegalCharacter2: 'The use of Chinese semicolon/Chinese comma/English semicolon separation is not supported. Please use English comma, to separate campaign id.',
        illegalCharacter3: 'The use of Chinese semicolon/Chinese comma/English semicolon separation is not supported. Please use English comma, to separate account id.',
        illegalCharacter4: 'The use of Chinese semicolon/Chinese comma/English comma separation is not supported. Please use English semicolon; to separate account id',
        illegalCharacter5: 'The use of Chinese semicolon/Chinese comma/English comma separation is not supported. Please use English semicolon; to separate Seller ID.',
        setWhite: 'Whitelist Setting',
        meidaWhiteList: 'Campaign Place or Orders in Batches',
        accountConfig: 'Account Place or Orders in Batches',
        sspTip1: ' is broad reach SSP and the operation is not supported.',
        sspTip2: ' is the SSP of D platform and the operation is not supported.',
        sspTip3: 'Parameter Error',
        actionDetails: 'Action Details',
        xandrAsset: 'Asset',
        detailTip: 'Detail',
        operate: 'Operate',
        reAuditTip: 'Manually Resubmit for Review',
        automaticResubmissionSetTitle: 'Automatic Resubmission Settings',
        reAssetIcon: 'When entering LP and clicking search, all unarchived assets using this Lp can be displayed from the drop-down list in the resubmit asset below.',
        reAssetTip: 'Resubmit for Review',
        tokenBlockDetail: 'Seller ID Token Block Detail',
        sellerIdText: 'The brand name has been blocked by some seller ID token in the media, click the button on the right side to check the detail',
        brandNameLanguageTip: 'Due to inconsistencies between the submission language and the media language, this brand name can\'t acquire msn traffic.',
        pendingReview: 'Pending Review',
        underReview: 'Under Review',
        reject: 'Reject',
        pass: 'Pass',
        auditFailure: 'Audit failure',
        reviewExpired: 'reviewExpired',
        brandNameReviewManagement: 'Brand Name Review Management',
        autoResubmissionSetTitle: 'Automatic Resubmission Settings',
        autoAuditAccountStatus: 'Automatically Resubmit Status',
        isSeller: 'Resubmission Condition Include Seller ID 280 Rejection',
        autoAuditAccountNum: 'Number of Automatic Re-submissions for Review',
        assetConditionSettings: 'Automatically Resubmit Asset Condition Settings',
        accountRange: 'Range of Account',
        campaignRange: 'Range of Campaign',
        assetRange: 'Asset Condition',
        all: 'All',
        oneDayTop: 'Top asset of this brand name at -1 day',
        anyAll: 'Any one of all',
        autoStatusOpt: 'Status：on/off；Number of Automatic Resubmissions：3；Range of Account：All；Range of Campaign：All；Asset Condition：Top asset of this brand name at -1 day',
        autoStatusTip: 'It is enabled by default. If it is closed manually, it will stop automatically resubmitting for review.',
        isSellerTip: 'The automatic resubmission condition defaults to rejection status, which does not include rejection status by seller ID 280. If you need to include this status, please select "Yes"',
        autoResubmissionNumTip: 'The number of times is a re-accumulation system. If you switch from 3 times to 5 times, the times will be recalculated.',
        accountAangeTip: 'The account displayed in the drop-down is the account to which the material corresponding to the Brand Name belongs.',
        campaignAangeTip: 'The Campaign displayed in the drop-down is the Campaign to which the material corresponding to the Brand Name belongs. If a specific account is selected in the above account range, it is the Campaign under the selected account.',
        assetAangeTip: 'The default is to submit for review based on the Top Asset income of the Asset corresponding to the Brand Name in the past day, excluding Assets that have been submitted for review and Assets that have been archived.',
        tokenBlock: 'Token Block',
        notTokenBlock: 'Not token block',
        sellerReason: 'Reason',
        reAsset: 'Resubmit Asset',
        xanderBrandName: 'Brand Name',
        auditStatus: 'Audit Status',
        xanderReason: 'Reason',
        reviewTime: 'Review Time',
        submissionTime: 'Submission Time',
        reviewLang: 'Language',
        mediaLanguage: 'Media Language',
        type: 'Type',
        asset: 'Asset',
        lp: 'LP',
        searchLp: 'Enter LP to search Asset',
         addCreativeConfig: 'There are some creatives under this account that have the same dimension configuration, do they need to be overwritten and deleted?',
         creativeTip: 'When selecting account dimension control, you can choose summary control and decentralized  control; summary control means that the sum of accounts does not exceed the set upper limit; ecentralized control means that all account controls are applied to all creative id under it.',
        updateCreative: 'The {num} creatives under the account already have configurations of the same dimension, do you want to update?',
        deleteCreative: 'There are {num} creatives under this account that have been configured or are controlled by other accounts, do they need to be overwritten and deleted?',
        controlMethod: 'Control Method',
        sourceOfControl: 'Source of Control',
        summaryControl: 'summary control',
        decentralizedControl: 'decentralized control',
        itself: 'itself',
        creativePlaceholder: 'please use commas to separate',
        campaginSearch: 'Campaign ID',
        companyLevel: 'Company Level',
        accountLevel: 'Account Level',
        deleteDimension: 'After deletion, the condition restriction for this dimension will become invalid. Are you sure to delete?',
        companyName: 'Company Name',
        accountName: 'Account Name',
        accountId: 'Account ID',
        campaginId: 'Campaign ID',
        dimension: 'Dimension',
        audienceAdd: '添加受眾定向',
        sensitiveTagAdd: '添加敏感標籤',
        sensitiveCfgAdd: '添加敏感標籤配置',
        // adx
        rejectedSources: '拒审来源',
        modifyAdx: '修改adx',
        addWhiteList: '批量操作白名單',
        addAdx: '新增ssp',
        adxSspId: 'sspid',
        adxSspName: 'ssp name',
        adxToken: 'token',
        adxHMedia: '是否為高級媒體',
        adxIp: '是否使用客户端ip',
        adxGEOEdge: 'GEO Edge',
        adxAddSuccess: '新建成功',
        adxModifySuccess: '修改成功',
        timezone: '時區',
        ssp: 'ssp',
        operateType: 'operateType',
        campaginName: '廣告計劃名稱',
        reason: 'Reason',
        creativeIdMsg: 'After deletion, no new rejection will be performed, and the assets that have been rejected will remain in the "Rejected" status.',
        rejectMsg: 'After termination, no new rejection will be performed, and the assets that have been rejected will remain in the "Rejected" status.',
        searchSpend: 'Search Spend',
        mediaRequire: 'media requirement',
        required: '{name} is required',
        dailyCost: 'Limit of Daily Spend',
        dailyImp: 'Limit of Daily Imp',
        assetImageMsg: 'After deletion, the conditional restrictions on the creative id will be invalid. Are you sure to delete the setting of this creative id?',
        dailyCostTip: 'The daily spend is the sum of the selected dimension consumption under the conditions set by the EST time zone. When the daily spend or imp any upper limit is reached, the corresponding traffic cannot be recalled.',
        dailyImpTip: 'The daily imp is the sum of the selected dimension consumption under the conditions set by the EST time zone. When the daily spend or imp any upper limit is reached, the corresponding traffic cannot be recalled.',
        countryTip: 'When multiple country are selected, they will be split for statistics, and the relationship between them and the selection in the dimension is N*N. For example, if you select the United States and the United Kingdom for the country, and account 1 is selected for the dimension, two pieces of data will appear on the page after submission.',

        hasCreativeId: 'The creative id already exists, please operate from the Edit entry in the action column',
        hasSppGroup: 'This SSP combination already exists, please edit it in the original combination',
        sitePlaceHolder: 'please use commas to separate',
        dailyCostValid: 'The daily cost must have no more than 2 decimal positions',
        element: '元素',
        country: '国家',
        language: '语言',
        content: '内容',
        rejectedReason: '拒审原因',
        brandName: 'Brand name',
        keyword: '标题',
        domainName: '落地页域名',
        operator: 'Operator',
        reviewer: '审核员',
        rejectAddSuccess: '添加成功',
        createTime: 'Create Time',
        completionTime: '完成时间',
        operatingTime: 'Operating Time',
        status: '状态',
        doing: '执行中，点击可终止',
        stop: '已终止',
        done: '已完成',
        download: '下载',
        detail: '查看',
        success: '成功',
        downloadToReject: '请下载详细内容以便二次确认拒审',
        noDownload: '请先下载详细内容再确认拒审',
        isConfrimReject: '是否确认拒审？',
        contentTitle: `【Creative ID 维度内容举例】
        素材图片url：https://d2cli4kgl5uxre.cloudfront.net/ML/c5d69c5de0bbac6490bda9d103btestd.png
        输入内容：c5d69c5de0bbac6490bda9d103btestd
        （https://d2cli4kgl5uxre.cloudfront.net/ML/和.png之间的部分）
        【品牌名称维度内容举例】
        品牌名称：Test Brand
        输入内容：Test Brand
        【标题维度内容举例】
        素材标题：Be careful if you have these illegal words around you!
        「情况1」输入内容：illegal words
        匹配：Be careful if you have these illegal words around you!;;These illegal words will affect your life
        「情况2」输入内容：Be careful if you have these illegal words around you!
        匹配：Be careful if you have these illegal words around you!
        【落地页域名维度内容举例】
        落地页域名：https://test.cloudfront.net/
        输入内容：test.cloudfront.net
        （https:// 和 /之间的部分）`,
        rejectTitle: '*2023年4月17日前的操作记录均展示为百度拒审。'
    },
    rejectCountry: {
        CA:	'加拿大',
        JP:	'日本',
        US: '美国',
        CH:	'瑞士',
        PT:	'葡萄牙',
        BE:	'比利时',
        MX:	'墨西哥',
        BR:	'巴西',
        HU:	'匈牙利',
        DK:	'丹麦',
        NO:	'挪威',
        CZ:	'捷克',
        SE:	'瑞典',
        PL:	'波兰',
        NL:	'荷兰',
        ES:	'西班牙',
        AT: '奥地利',
        IT:	'意大利',
        GB:	'英国',
        AU: '澳大利亚',
        KR: '韩国',
        TW:	'台湾',
        ID:	'印度尼西亚',
        SG:	'新加坡',
        MY: '马来西亚',
        TH:	'泰国',
        HK:	'香港',
        DE:	'德国',
        FR:	'法国'
    },
    rejectLanguage: {
        en: '英语',
        de: '德语',
        pt: '葡萄牙语',
        fr: '法语',
        es: '西班牙语',
        hu: '匈牙利语',
        da: '丹麦语',
        nb: '挪威语',
        cs: '捷克语',
        sv: '瑞典语',
        pl: '波兰语',
        nl: '荷兰语',
        it: '意大利语'
    },
    rejectReason: {
        celebrity: '因不合规名人标题而被拒绝',
        Cryptocurrency: '由于不合规的加密货币标题而被拒绝',
        suffering: '由于不合规的痛苦/暴力标题而被拒绝',
        sensitive: '因不合规敏感标题而被拒绝'
    }
};
