/*
 * @file cn.js
 * @author liushengxgi
 * @date 2020-09-03 10:52:17
 * ----
 * @last Modified by liushengxgi
 * @last Modified time 2020-09-03 11:00:49
 */

export default {
    menuData: {
        dashboardTitle: '控制台',
        editorialTitle: '编辑工具',
        domainTitle: '域名屏蔽',
        authorizeTitle: '权限管理',
        roleTitle: '角色管理',
        accountTitle: '账户管理',
        userTitle: '用户管理',
        managementTitle: '媒体管理',
        audienceTitle: '受众定向配置',
        sensitiveTagTitle: '敏感标签列表',
        sensitiveCfgTitle: '配置敏感标签',
        adxTitle: 'ssp管理',
        discoveryBWconfigTitle: 'D_SSP level 黑白名單',
        siteTitle: '站点管理',
        mediaTagTitle: '标签管理',
        mediaBWconfigTitle: '账户层级媒体黑白名单',
        mediaBWconfig: '账户层级媒体黑白名单',
        mediaSellerIdConfigTitle: '账户层级Seller ID 黑白名单',
        cruiseRejectBlackListTitle: '巡航拒审',
        auditRejectOnceTitle: '单次拒审',
        creativeRiskTitle: '图片素材风控',
        xandrAuditManagment: 'Brand Name送审管理',
        xandrAuditManagmentTitle: 'Brand Name送审管理',
        accountSiteFilter: 'account级别-site流量过滤',
        accountSiteFilterTitle: 'account级别-site流量过滤'
    },
    button: {
        addAccount: '添加账户',
        addUser: '添加用户',
        addRole: '添加角色',
        status: '状态',
        del: '删除',
        bwList: '黑白名单'
    },
    dashboard: {
        bwType: '黑白名单类型',
        mediaSearchAcc: '根据账户搜索用户',
        rpm: 'RPM',
        vrpm: 'vRPM',
        matchedRequest: '匹配的广告请求',
        returnAds: '返回广告',
        viewsWithAds: '有广告的观看次数',
        clicks: '广告点击',
        withAds: '% with Ads',
        withVisibility: '% with Visibility',
        vctr: 'vCTR',
        rendersWithAds: '广告渲染',
        winRate: 'Win Rate',
        avgCPC: '平均每次点击费用',
        accountName: '用户名',
        // Dimension
        dimensionDate: '日期',
        dimensionAccount: '帐户',
        dimensionSSP: '媒体平台',
        dimensionSite: '站点',
        payin: '支付'
    },
    editorial: {
        domain: {
            searchPlaceholder: '搜索域名/账户名称',
            remove: '从列表中移除',
            block: '添加屏蔽域名'
        }
    },
    permission: {},
    management: {
        rejectAutoAudit: '拒审，自动重新送审审核中',
        noLimit: '不限',
        yes: '是',
        no: '否',
        illegalCharacter1: '不支持使用中文分号/中文逗号/英文分号分隔，请使用英文逗号,分隔ssp',
        illegalCharacter2: '不支持使用中文分号/中文逗号/英文分号分隔，请使用英文逗号,分隔campaign id',
        illegalCharacter3: '不支持使用中文分号/中文逗号/英文分号分隔，请使用英文逗号,分隔account id',
        illegalCharacter4: '不支持使用中文分号/中文逗号/英文逗号分隔，请使用英文分号;分隔account id',
        illegalCharacter5: '不支持使用中文分号/中文逗号/英文逗号分隔，请使用英文分号;分隔Seller ID.',
        setWhite: '白名单配置权限',
        meidaWhiteList: 'Campaign维度批量上下单',
        accountConfig: '账户维度批量上下单',
        sspTip1: '为通投SSP，不支持进行上下单操作',
        sspTip2: '为D的SSP，不支持进行上下单操作',
        sspTip3: '参数错误',
        actionDetails: '操作记录',
        detailTip: '详情',
        operate: '操作',
        reAssetIcon: '输入LP点击搜索时，下面重新送审Asset中下拉可显示使用了该Lp的所有未归档Asset。',
        tokenBlockDetail: 'Seller ID Token Block详情',
        reAuditTip: '人工重新送审',
        automaticResubmissionSetTitle: '自动重新送审设置',
        sellerIdText: '该品牌名称已被部分seller ID token block，点击右侧 按钮了解详情',
        brandNameLanguageTip: '该品牌名称因送审语言≠媒体语言，无法获取MSN流量',
        pendingReview: '待送审',
        underReview: '审核中',
        reject: '拒审',
        pass: '通过',
        auditFailure: '送审失败',
        reviewExpired: '审核过期',
        brandNameReviewManagement: 'Brand Name送审管理',
        autoResubmissionSetTitle: '自动重新送审设置',
        autoAuditAccountStatus: '自动重新送审状态',
        isSeller: '送审条件是否包含seller ID 280拒审',
        autoAuditAccountNum: '自动重新送审次数',
        assetConditionSettings: '自动重新送审素材条件设置',
        accountRange: '账户范围',
        campaignRange: 'Campaign范围',
        assetRange: 'Asset 条件',
        all: 'All',
        oneDayTop: '按过去一天收入Top',
        anyAll: '随机',
        autoStatusOpt: '开关状态：开启；送审次数：3；账户范围：不限；Campaign范围：不限，Asset条件：按过去一天收入Top',
        autoStatusTip: '默认开启，人工关闭后将会停止自动重新送审',
        isSellerTip: '自动重新送审条件默认为拒审状态，不包含被seller ID 280拒审状态，如果需要包含该状态，请选择"是"',
        autoResubmissionNumTip: '次数为重新累积制度，如从3次切换到5次，重新计算次数',
        accountAangeTip: '下拉中显示账户为Brand Name对应素材所属账户',
        campaignAangeTip: '下拉中显示Campaign为Brand Name对应素材所属Campaign，如上述账户范围选择了具体账户，则为所选账户下的Campaign',
        assetAangeTip: '默认为按Brand Name所对应Asset过去一天收入Top Asset进行送审，排除已经送审过Asset和已经归档Asset',
        tokenBlock: '已被token block',
        notTokenBlock: '未被token block',
        sellerReason: '原因',
        reAsset: '重新送审Asset',
        xanderBrandName: '品牌名称',
        auditStatus: '审核状态',
        xanderReason: '拒审原因',
        reviewTime: '审核时间',
        submissionTime: '送审时间',
        reviewLang: '送审语言',
        mediaLanguage: '媒体语言',
        type: '送审广告类型',
        asset: '送审素材',
        lp: '当前送审LP',
        xandrAsset: '当前送审素材',
        searchLp: '输入LP搜索Asset',
        addCreativeConfig: '账户下已有creative有相同维度配置，是否选择覆盖配置？',
        creativeTip: '当选择账户维度控制时，可选择汇总控制和平均控制；汇总控制为账户的总和不超过设定的上限值；平均控制为将账户的控制全部作用于其下所有的Creative ID',
        updateCreative: '账户下{num}条creative已有同维度配置，是否需要覆盖其配置? ',
        deleteCreative: '该账户下有{num}条creative已有配置或受其他账户控制，是否需要覆盖删除？',
        controlMethod: '控制方式',
        sourceOfControl: '控制来源',
        summaryControl: '汇总控制',
        decentralizedControl: '下放控制',
        itself: '自身',
        creativePlaceholder: '请输入Creative ID，并且使用,分隔',
        companyLevel: '公司层级',
        accountLevel: '账户层级',
        deleteDimension: '删除后，针对该维度的条件限制将失效，是否确认删除？',
        companyName: '公司名称',
        accountName: '账户名称',
        accountId: '账户ID',
        campaginId: '广告计划',
        campaginSearch: '广告计划 ID',
        dimension: '维度',
        audienceAdd: '添加受众定向',
        sensitiveTagAdd: '添加敏感标签',
        sensitiveCfgAdd: '添加敏感标签配置',
        // adx
        rejectedSources: '拒审来源',
        modifyAdx: '修改adx',
        addWhiteList: '批量操作白名单',
        setWhiteForD: 'Discovery媒体白名单配置',
        setDisplaySsp: 'Display SSP自动上单配置',
        addAdx: '新增ssp',
        adxSspId: 'sspid',
        adxSspName: 'ssp名称',
        adxToken: 'token',
        adxHMedia: '是否为高级媒体',
        adxIp: '是否使用客户端ip',
        adxGEOEdge: 'GEO Edge',
        adxAddSuccess: '新建成功',
        adxModifySuccess: '修改成功',
        timezone: '时区',
        ssp: 'ssp',
        operateType: '操作类型',
        campaginName: '广告计划名称',
        reason: '理由',
        creativeIdMsg: '删除后，将不再执行新的拒审，已经被拒审的asset仍保留“拒审“状态。',
        rejectMsg: '终止后，将不再执行新的拒审，已经被拒审的asset仍保留“拒审“状态。',
        searchSpend: '查询花费',
        mediaRequire: '媒体要求',
        required: '{name} 不能为空',
        dailyCost: '单日消耗上限',
        dailyImp: '单日展示上限',
        addNewConfig: '新增设置',
        assetImageMsg: '删除后，针对该Creative ID的条件限制将失效，是否确认删除？',
        dailyCostTip: '单日消耗为EST时区所设置条件下所选维度消耗总和，当触达单日消耗或展示任意上限，则对应流量不可召回。',
        dailyImpTip: '单日消耗为EST时区所设置条件下所选维度消耗总和，当触达单日消耗或展示任意上限，则对应流量不可召回。',
        countryTip: '多选地区时，其会拆分进行统计，其与维度中选择为N*N关系。例如，地区选择美国，英国，维度中选择了账户1，则提交后页面会出现2条数据，账户1在美国，账户1在英国的控制。',
        hasCreativeId: '该Creative ID已存在，请从操作列中编辑笔入口进行操作',
        hasSppGroup: '该SSP组合已存在，请在原有组合中编辑',
        sitePlaceHolder: '支持批量添加媒体站点，请使用,分隔',
        dailyCostValid: '单日消耗只能保留2位小数点',
        element: '元素',
        country: '国家',
        language: '语言',
        content: '内容',
        rejectedReason: '拒审原因',
        brandName: '品牌名称',
        keyword: '标题',
        domainName: '落地页域名',
        operator: '操作人',
        reviewer: '审核员',
        rejectAddSuccess: '添加成功',
        createTime: '创建时间',
        completionTime: '完成时间',
        operatingTime: '操作时间',
        status: '状态',
        doing: '执行中，点击可终止',
        stop: '已终止',
        done: '已完成',
        download: '下载',
        detail: '查看',
        success: '成功',
        downloadToReject: '请下载详细内容以便二次确认拒审',
        noDownload: '请先下载详细内容再确认拒审',
        isConfrimReject: '是否确认拒审？',
        contentTitle: `【Creative ID 维度内容举例】
        素材图片url：https://d2cli4kgl5uxre.cloudfront.net/ML/c5d69c5de0bbac6490bda9d103btestd.png
        输入内容：c5d69c5de0bbac6490bda9d103btestd
        （https://d2cli4kgl5uxre.cloudfront.net/ML/和.png之间的部分）
        【品牌名称维度内容举例】
        品牌名称：Test Brand
        输入内容：Test Brand
        【标题维度内容举例】
        素材标题：Be careful if you have these illegal words around you!
        「情况1」输入内容：illegal words
        匹配：Be careful if you have these illegal words around you!;;These illegal words will affect your life
        「情况2」输入内容：Be careful if you have these illegal words around you!
        匹配：Be careful if you have these illegal words around you!
        【落地页域名维度内容举例】
        落地页域名：https://test.cloudfront.net/
        输入内容：test.cloudfront.net
        （https:// 和 /之间的部分）`,
        rejectTitle: '* 2023年4月17日前的操作记录均展示为百度拒审。'
    },
    rejectCountry: {
        CA:	'加拿大',
        JP:	'日本',
        US: '美国',
        CH:	'瑞士',
        PT:	'葡萄牙',
        BE:	'比利时',
        MX:	'墨西哥',
        BR:	'巴西',
        HU:	'匈牙利',
        DK:	'丹麦',
        NO:	'挪威',
        CZ:	'捷克',
        SE:	'瑞典',
        PL:	'波兰',
        NL:	'荷兰',
        ES:	'西班牙',
        AT: '奥地利',
        IT:	'意大利',
        GB:	'英国',
        AU: '澳大利亚',
        KR: '韩国',
        TW:	'台湾',
        ID:	'印度尼西亚',
        SG:	'新加坡',
        MY: '马来西亚',
        TH:	'泰国',
        HK:	'香港',
        DE:	'德国',
        FR:	'法国'
    },
    rejectLanguage: {
        en: '英语',
        de: '德语',
        pt: '葡萄牙语',
        fr: '法语',
        es: '西班牙语',
        hu: '匈牙利语',
        da: '丹麦语',
        nb: '挪威语',
        cs: '捷克语',
        sv: '瑞典语',
        pl: '波兰语',
        nl: '荷兰语',
        it: '意大利语'
    },
    rejectReason: {
        celebrity: '因不合规名人标题而被拒绝',
        Cryptocurrency: '由于不合规的加密货币标题而被拒绝',
        suffering: '由于不合规的痛苦/暴力标题而被拒绝',
        sensitive: '因不合规敏感标题而被拒绝'
    }
};
